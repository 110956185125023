// WARNING: This stylesheet is being used in multiple files. Exercise caution while making changes.

// TODO - @aditya: Move this stylesheet inside the <template_name> folder directly and update imports accordingly.

@import "@Static/css/common";

.sectionTitle {
    font-size: 40px;
    @include colorCombination1(
        (
            "tc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
}

.root {
    overflow-x: hidden;
    @include colorCombinationXYZ(
        (
            "tc": (
                "key": 1,
            ),
            "bgc": (
                "key": 2,
            ),
            "boc": (
                "key": 1,
            ),
        )
    );
}

@media (max-width: 600px) {
    .sectionTitle {
        font-size: 25px;
    }
}
