@import "@Static/css/common";

.cardOuterContainer {
    position: relative;
    margin: 0 10px;
    margin-bottom: 40px;
    cursor: pointer;

        transition: 0.2s ease all;

        &:hover {
            transform: translateY(-4px) scale(1.01);
        }
}

.cardContainer {
    position: relative;
    text-align: left;
    background-color: silver;
    width: 416px;
    max-width: calc(
        100vw - 40px
    ); // Max screen width minus the container padding
    margin: auto;
    height: 240px;

    .eventTagView {
        margin-top: 5px;
        display: flex;
    }
    .tagTitleStyle {
        border-radius: 50px;
        padding: 5px 10px;
        text-overflow: ellipsis;
    }
}

.cardImgContent {
    position: absolute;
    top: 0px;
    width: 100%;
    padding: 4px 12px;
}

.cardImgContainer img {
    width: 416px;
    height: 240px;
}

.heartIcon,
.shareIcon {
    width: 30px;
    height: 30px;
    margin: 2px;
    cursor: pointer;
}

.leftContent {
    color: #ffffff;
    padding: 4px 12px;
    border-radius: 3px 0 0 3px;
    font-size: 14px;
    margin: 7px 0px;
    position: relative;
    left: -16px;
}

.rightContent {
    display: flex;
    float: right;
    margin: 7px -6px;
}

.contentCard {
    .slotBookContainer {
        margin: -26px 15px;
        width: 367px;
    }
}

@media only screen and (max-width: 768px) {
    .cardOuterContainer {
        margin: 0 0 40px;
        max-width: calc(100vw - 40px);
    }

    .cardContainer {
        max-height: calc(240 / 416 * (100vw - 40px));
    }

    .cardImgContent {
        width: 100%;
    }

    .cardImgContainer img {
        max-width: calc(100vw - 40px);
        max-height: calc(240 / 416 * (100vw - 40px));
    }
    .contentCard .slotBookContainer {
        margin: -26px 10px;
        width: 256px;
    }

    .leftContent {
        font-size: 12px;
    }
    .cardContainer {
        .tagTitleStyle {
            font-size: 10px;
        }
    }
}
