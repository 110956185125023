@import "@Static/css/common";

.browseSectionContainer {
    padding: 20px;
    text-align: left;
    display: block;
    overflow: hidden;
    max-width: 1480px;
    margin: auto;
}

.cardSection {
    overflow: hidden;
}

.cardSection {
    .cards {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 6px 0 0;
    }
}

.anchorBrowse {
    border: none !important;
    color: #393939 !important;
    text-decoration: none !important;
    position: absolute;
    transform: translateY(-200px);
}

.a:hover {
    color: transparent;
    text-decoration: none;
}

.browseSectionTitle {
    display: flex;
    margin-bottom: 30px;
    .browseTitle {
        font-size: 40px;
        font-weight: 600;
        @include colorCombination1(("tc": ("color": (237, 72, 83))));
        text-align: left;
        padding: 10px;
        padding-left: 0;
    }

    .browseArrow {
        margin-top: 25px;

        svg {
            path {
                fill: getColorValue("1");
            }
        }
    }
}

.loadingSpinner {
    margin-left: calc(50% - 30px);
    margin-bottom: 8rem;
}

.loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: colorCombination("primary");
    width: 30px;
    height: 30px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
    position: absolute;
    left: 50%;
}

.anchorEventSection {
    transform: translateY(-100px);
    position: absolute;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@media only screen and (max-width: 768px) {
    .browseSectionContainer {
        margin: 20px 0;
    }

    .browseSectionTitle {
        .browseTitle {
            font-size: 25px;
            padding: 10px 0;
        }
        .arrow {
            display: none;
        }
    }
    .cardSection {
        text-align: center;
        .cards {
            justify-content: center;
        }
    }
    .cardSection .cardOuterContainer {
        display: inline-block;
        margin: 30px 0px 20px;
        text-align: center;
    }
}
