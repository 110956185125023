.button {
    font-size: 31px;
    font-weight: 600;
    height: 55px;
    padding: 0 20px;
    margin-right: 10px;
    white-space: pre;
    max-width: 30ch;
    text-overflow: ellipsis;
    overflow: hidden;
}

@media only screen and (max-width: 768px) {
    .button {
        height: 26px;
        font-size: 12px;
        max-width: 26ch;
    }
}
