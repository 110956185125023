@import "@Static/css/common";

.slotBookContainer {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    background: white;
    width: 395px;
    text-align: left;
    padding: 10px;
    margin: auto;
    margin-top: -20px;
    transition: 0.1s ease all;
    color: #333;
    &:hover {
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.4);
    }
}

.slotTitle {
    display: flex;
    justify-content: space-between;
}
.slotTitle1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 36px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 75px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.subTitle {
    font-size: 12px;
    line-height: 12px;
    letter-spacing: -0.22px;
    margin-bottom: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.period {
    background-color: #fff0f0;
    border-radius: 3px;
    padding: 5px;
    min-width: 75px;
    text-align: center;
    height: fit-content;
    // margin: auto;
}

.bookSlot {
    display: inline-block;
    button {
        height: 32px;
        font-size: 14px;
        @include colorCombination2(
            (
                "bgc": (
                    "color": (
                        237,
                        72,
                        83,
                    ),
                ),
            )
        );
        color: white;
        outline: none;
        border: none;
        width: auto;
    }
}

.amt {
    display: inline-block;
    float: right;
    @include colorCombination1(
        (
            "tc": (
                "color": (
                    237,
                    72,
                    83,
                ),
            ),
        )
    );
    font-size: 20px;
}

.arrow1 {
    padding-left: 3px;
}
.buttonMargin {
    margin-top: 22px;
}

.bookContainer {
    display: flex;
    justify-content: space-between;
}
@keyframes shimmer {
    0% {
        background-position: -1000px 0;
    }
    100% {
        background-position: 1000px 0;
    }
}
.shimmer {
    animation: shimmer 2s infinite;
    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
    background-size: 1000px 100%;
    height: 30px;
    width: 50px;
}

.ctaButton {
    font-size: 31px;
    font-weight: 600;
    height: 55px;
    padding: 0 20px;
    margin-right: 10px;
    white-space: pre;
    max-width: 30ch;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    transition: 0.15s ease all;
    &:hover {
        filter: brightness(1.15);
    }
}

@media only screen and (max-width: 768px) {
    .ctaButton {
        height: 26px;
        font-size: 12px;
        max-width: 26ch;
    }
    .slotTitle1 {
        font-size: 16px;
        line-height: 20px;
        height: 40px;
    }

    .slotBookContainer {
        width: 90%;
        margin: -20px auto 0;
        width: calc(100% - 20px);
        max-width: 416px;
        justify-content: center;
    }

    .subTitle,
    .rating {
        font-size: 10px;
        line-height: 20px;
    }
    .amt {
        font-size: 15px;
        margin-top: 10px;
    }

    .rating .star {
        width: 10px;
        height: 10px;
    }

    .period {
        font-size: 10px;
        width: auto;
        min-width: 55px;
    }
    .bookSlot {
        // Two different selectors for the same element due to how the cascading override works in CSS
        & .buttonContainer button {
            height: 24px;
        }
        button {
            font-size: 13px;
        }
    }
    .buttonMargin {
        margin-top: 31px;
    }
}
