@import "@Static/css/common";

.mainPageContainer {
    position: relative;
    padding-top: 61px;
    @include colorCombinationXYZ(
        (
            "bgc": (
                "key": 1,
            ),
        )
    );
    .pageContainer {
        background-size: cover;
        background-attachment: inherit;
        height: 700px;
        position: relative;
        &.darken {
            background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8));
            .coverPic {
                opacity: 0.6;
            }
            .containerData {
                background-color: #0007;
            }
        }
    }

    .coverPic {
        width: 100%;
        height: 700px;
        object-fit: cover;
    }

    .containerData {
        position: absolute;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        margin: auto;
        height: 100%;
        top: 0;
        overflow: hidden;
    }

    .heading1 {
        font-size: 60px;
        color: #ffff;
        margin-bottom: 31px;
        text-align: center;
        word-wrap: break-word;
        font-weight: 400;
        font-style: normal;
        transition: color 0.2s ease-in-out;
        letter-spacing: 0.2em;
        text-transform: uppercase;
    }

    .heading2 {
        font-size: 40px;
        color: #ffff;
        text-transform: capitalize;
        font-weight: 400;
        text-align: center;
        padding: 0 40px;
    }

    .buttonBlock {
        margin-top: 60px;
        text-align: center;

        .bookButton {
            display: inline-block;
            margin-right: 20px;

            button {
                @include colorCombination2(
                    (
                        "bgc": (
                            "color": (
                                237,
                                72,
                                83,
                            ),
                        ),
                    )
                );
                outline: none;
                border: none;
                color: #ffff;
                width: auto;
            }
        }
    }
}

.getButton {
    display: inline-block;
    background-color: transparent;
    border: 1px solid #ffff;
    color: #ffff;
    padding: 8px 12px;
    border-radius: 100px;
}

@media only screen and (max-width: 1025px) {
    .mainPageContainer {
        height: (
            #{216 / 400 * 100vw} + 60px
        ); // 216 / 400 is the desired aspect ratio
        padding-top: 56px;
        text {
            display: block;
            text-align: center;
            font-size: 18px;
            color: #f2f2f4;
        }
    }
    .containerData {
        // top: 70% !important;
    }

    .pageContainer {
        height: auto !important;
    }
    .coverPic {
        height: #{216 / 400 * 100vw} !important;
        object-fit: cover;
        background-color: silver;
    }
    .mainPageContainer .heading1 {
        font-size: 18px;
        line-height: 1.3;
        margin-bottom: 15px;
        padding: 12px 0 0px;
    }
    .mainPageContainer .heading2 {
        font-size: 12px;
        line-height: 1.4;
        font-weight: 700;
        padding: 0;
    }
    .buttonBlock {
        margin-top: 6px !important;

        .bookButton {
            display: inline-block;
            margin: auto !important;
            button {
                outline: none;
                border: none;
                width: auto;
                font-size: 17px;
                height: 35px;
                margin: auto;
            }
        }
    }
    .getButton {
        font-size: 17px;
    }
}

@media (min-width: 769px) and (max-width: 1299px) {
    .mainPageContainer .heading1 {
        font-size: 35px;
    }
    .mainPageContainer .heading2 {
        padding: 40px 20px !important;
        font-size: 20px;
    }
}
